import Menu from "../components/menu";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import CategoryList from "../components/CategoryList";
import ArticleCard from "../components/ArticleCard";
import { Link, useParams } from "react-router-dom";

function Articles() {
    const { t, i18n } = useTranslation();
    const [articles, setArticles]= useState();
    const [updateSelected, setUpdateSelected]= useState(false);
    const [term, setTerm]= useState("");
    const [links, setLinks]= useState([]);
    const {page} = useParams()
    const [setectedCategories, setSelectedCategories]= useState({
        'categories' : [],
        'sub_categories' : []
      });
   
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://admin.mithaqaltashrie.com.sa/api/articles?page=${page}`);
            
            if (Array.isArray(response.data.articles.data)) {
                const visibleArticles = []
                response.data.articles.data.map((article)=>{
                    visibleArticles.push({article, visibility:true})
                })
                setArticles(visibleArticles)
                setLinks(response.data.articles.links);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData();
      }, [page]);
      useEffect(()=>{
      },[updateSelected])
      const handleChange = (e) => {
        const searchTerm = e.target.value;
        setTerm(searchTerm);
    
        const updatedArticles = articles?.map((article) => {
            const isVisible = 
                searchTerm === "" || 
                article.article.title.toUpperCase().includes(searchTerm.toUpperCase());
            return { ...article, visibility: isVisible };
        });
    
        setArticles(updatedArticles);
        setUpdateSelected((prev) => !prev);
    };

    const getSelectedCategories = (data)=>{
        setSelectedCategories(data)
        const updatedArticles = articles
        if(setectedCategories.categories.length===0 && setectedCategories.sub_categories.length===0 ){
            updatedArticles.forEach((article)=>{
                article.visibility = true
            })
            
        

        }
        else{
        updatedArticles.forEach((article)=>{
             if(setectedCategories.categories.indexOf(article.article.category_id)===-1 && setectedCategories.sub_categories.indexOf(article.article.sub_category_id)===-1){
                article.visibility = false
            } 
            if(setectedCategories.categories.indexOf(article.article.category_id)!==-1 || setectedCategories.sub_categories.indexOf(article.article.sub_category_id)!==-1){
                article.visibility = true
            } 
        })
       
    }
    setArticles(updatedArticles)
    setUpdateSelected(!updateSelected)


    }
    return(
        <>
        <Menu/>
        
        <div className="bg-secondary w-full relative h-28 md:h-72 flex flex-col justify-center">
            <img src="/images/overlay-5.png" className='absolute h-32 md:h-80 -bottom-4 md:-bottom-8 left-0 0 z-10' alt="" />
            <div className="relative flex px-4 md:px-10 lg:px-20 xl:px-36">
                <p className="text-2xl md:text-3xl lg:text-4xl xl:text-6xl font-extrabold text-blue-semilight">
                    {t("Stay updated to the")}  <span className="text-primary">{t("Newest")}<br/>{t("legal")}</span> {t("information")}
                </p>
            </div>
        </div>
        <div className="w-full bg-white py-16 mx-auto px-4 md:px-0 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
            <div className="w-full">
                <p className="text-blue-dark px-4 text-2xl lg:text-[40px] font-bold font-['Roboto']">{t("Mithaq Al Tashrie: Article collection")}</p>
                { <form >
                <div className="flex justify-between w-full lg:gap-x-10 mt-6 lg:mt-10">
                    <div className="w-full">
                        <input 
                            type="text" placeholder={t("Search an article")} 
                            className="w-full px-4 py-2 bg-white rounded-full border border-blue placeholder:text-blue-dark placeholder:font-extralight" 
                            value={term}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="hidden lg:block">
                        <input 
                            className="px-14 py-2.5 bg-secondary text-white rounded-full font-bold"
                            type="submit"
                            value={t("search")}
                        />      
                    </div>
                </div></form> }
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 mt-8 lg:mt-14 gap-5">
                <div className="lg:col-span-1 md:col-span-2">
                    <CategoryList selectedCategories={getSelectedCategories}/>
                </div>
                <div className="lg:col-span-3 grid md:grid-cols-2 md:col-span-2 lg:grid-cols-3 gap-5 ">
                    {articles &&  articles.map((article)=>{

                    
                        if(article.visibility===true)
                       return(<ArticleCard article={article.article} key={article.article.id} />)
                        } )
                    } 
                </div>

            </div>
            <div className='mt-10 flex flex-row-reverse'>
                <div>
                    {links && links.map((link)=>{
                        const pageNumber = link.url ? new URL(link.url).searchParams.get("page") : 1;
                        return <Link key={link.label} to={"/articles/"+pageNumber}><span className="shadow mx-1 p-2 bg-blue-light text-gray hover:bg-secondary hover:text-white rounded">{" "+t(link.label)+" "} </span></Link>
                    })}
                </div>
            </div>
        </div>
            <Footer/>
        </>
    );
}
export default Articles