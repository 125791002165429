import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

function Cases() {
  const {t} = useTranslation();
  const [articles, setArticles]= useState([]);

  function getFirst(text) {
    const words = text.split(' '); 
    if (words.length <= 3) {
      return text;
    } else {
      return words.slice(0, 3).join(' ') + '...';
    }
  }
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://admin.mithaqaltashrie.com.sa/api/articles');
          if (Array.isArray(response.data.articles.data)) {
            setArticles(response.data.articles.data.reverse().slice(0, 3));
          } else {
            console.error('API response is not an array');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);
  return (
    <div className='bg-white px-4 py-16 lg:p-16'>
      <div className="flex justify-between gap-x-2">
        <div>
          <p className="text-2xl lg:text-3xl text-secondary font-semibold leading-relaxed">{t("case-studies")}</p>
          <p className="text-gray">{t("cases-subtitle")}</p>
        </div>
        <Link to={"/articles"} className='rounded-2xl bg-white text-secondary py-2 px-4 lg:py-3 lg:px-6 text-center border border-primary-dark h-fit'>
            <p className='text-sm'>{t('see-more')}</p>
        </Link>
      </div>
      <div className="mt-8 grid md:grid-cols-3 gap-3">
      {articles.map((article)=>(
        <Link key={article.id} to={"/articles/"+article.id}>
        <div className="w-full relative rounded-2xl h-fit">
          {article.image
          ? (
            <img src={"https://admin.mithaqaltashrie.com.sa/storage/"+article.image} className='w-full h-60 rounded-2xl' alt="" />
          ): (
            <img src="/images/case-2.jpg" className='w-full rounded-2xl' alt="" />
          )
          }
          <div className='w-full h-full absolute bottom-0 left-0 rounded-2xl bg-gradient-to-t from-black opacity-80'></div>
          <div className='w-full absolute bottom-5 px-6'>
            <p className="text-white text-2xl font-bold" title={article.title}>{getFirst(article.title)}</p>
            <p className="text-white text-xl font-normal">{article.writer}</p>
          </div>
        </div>
        </Link>
      ))}
      </div>
    </div>
  )
}

export default Cases